import React, { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import {
  BODY_FONT_LARGE,
  PAGE_MARGIN_TOP_LARGE,
  BODY_LETTER_SPACING_LARGE,
  INNER_MARGIN_LEFT_MOBILE,
  INNER_MARGIN_RIGHT_MOBILE,
} from '@config/layout';
import { getSiteTextFactory } from '@utils/siteText';
import { StyledMarkdown } from './StyledMarkdown';
import UIStore from '@stores/UIStore';
import { mobileMQ } from '@config/mediaQueries';
import { ContactProps } from 'src/templates/Contact';

const Container = styled.div(
  adaptForLargeScreen({
    marginTop: PAGE_MARGIN_TOP_LARGE,
    [mobileMQ]: { marginTop: 116 },
  }),
);

const Text = styled.div(
  adaptForLargeScreen({
    fontSize: BODY_FONT_LARGE,
    marginLeft: 663,
    marginRight: 160,
    marginBottom: 365,
    letterSpacing: BODY_LETTER_SPACING_LARGE,

    [mobileMQ]: {
      marginLeft: INNER_MARGIN_LEFT_MOBILE,
      marginRight: INNER_MARGIN_RIGHT_MOBILE,
      fontSize: 25,
      lineHeight: 1.4,
      marginBottom: 170,
    },
  }),
);

export const Contact = (props: ContactProps) => {
  const { siteText } = props;
  const getSiteText = getSiteTextFactory(siteText, 'Contact');

  useLayoutEffect(() => UIStore.setupPage('#000000', '#ffffff'), []);

  return (
    <Container>
      <Text>
        <StyledMarkdown source={getSiteText('Text')} />
      </Text>
    </Container>
  );
};
