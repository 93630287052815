import React, { Fragment } from 'react';

import { Meta } from '@components/Meta';
import { Contact } from '@components/Contact';
import { Footer } from '@components/Footer';
import { Layout } from '@components/Layout';

export type ContactProps = {
  siteText: SiteText[];
} & BasePageProps;

type Props = { pageContext: ContactProps };

export default ({ pageContext: { homepageFonts, siteText } }: Props) => (
  <Fragment>
    <Meta title="Contact" />

    <Layout homepageFonts={homepageFonts}>
      <Contact siteText={siteText} />
    </Layout>

    <Footer />
  </Fragment>
);
